import dayjs from "dayjs";
import axios from "../axios";
import Compress from "compress.js";
import Resizer from "react-image-file-resizer";

const isDev = process.env.NODE_ENV !== "production";

export const separator = "#$#!@*(#";

export const intMonthToStr = (intMonth) => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  return months[intMonth - 1];
};

/**

Returns a formatted date string representing the input date in a user-friendly format.
@param {string | Date} date - The input date string or Date object to be formatted.
@returns {string} The formatted date string in the format of "27 Apr 2023".
*/

export const getPrettyDate = (date) => {
  const formattedDate = dayjs(date).format("DD MM YYYY");
  return formattedDate;
};

export const sameDates = (date1, date2) => {
  date1 = dayjs(date1);
  date2 = dayjs(date2);

  return date1.isSame(date2, "day");
};

export const getPrettyDateDayMonth = (dateStr) => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const date = new Date(dateStr);
  const day = date.getDate();
  const month = months[date.getMonth()];

  // Function to add ordinal suffix to day
  function getOrdinalSuffix(n) {
    if (n > 3 && n < 21) return "th"; // covers 4th to 20th
    switch (n % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  }

  return `${day}${getOrdinalSuffix(day)} ${month}`;
};

export const dashDateToNiceDate = (date) => {
  if (date) {
    date = dayjs(date).format("DD-MM-YYYY");
    const [day, month, year] = date.split("-");
    const strMonth = intMonthToStr(month);
    return `${day} ${strMonth} ${year.slice(2)}`;
  }
};

export const dashDateToShortDay = (date) => {
  if (date) {
    date = dayjs(date).format("DD-MM-YYYY");
    const [day, month, year] = date.split("-");
    const strMonth = intMonthToStr(month);
    return `${day} ${strMonth}`;
  }
};

export const getTodayDate = () => {
  const dateJs = dayjs().format("DD-MM-YYYY");
  return dateJs;
};

export const getTodayNiceDate = () => {
  const dateJs = dayjs().format("DD-MM-YYYY");
  const [day, month, year] = dateJs.split("-");
  const strMonth = intMonthToStr(month);
  return `${day} ${strMonth} ${year.slice(2)}`;
};

export const date1BeforeDate2 = (date1, date2) => {
  if (dayjs(date1).isBefore(dayjs(date2))) {
    return true;
  }
  return false;
};

export const date1AfterDate2 = (date1, date2) => {
  if (dayjs(date1).isAfter(dayjs(date2))) {
    return true;
  }
  return false;
};

export const getDayOfWeek = (date) => {
  if (date) {
    const dateJs = dayjs(date);
    const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const dayOfWeekIndex = dateJs.day();
    return daysOfWeek[dayOfWeekIndex];
  }
};

export const dateRangeFormattingShortYear = (startDate, endDate) => {
  if (startDate && endDate) {
    startDate = dayjs(startDate).format("DD-MM-YYYY");
    endDate = dayjs(endDate).format("DD-MM-YYYY");
    const [startDay, startMonth, startYear] = startDate.split("-");
    const [endDay, endMonth, endYear] = endDate.split("-");
    if (startDate == endDate) {
      const strMonth = intMonthToStr(startMonth);
      return `${startDay} ${strMonth} ${startYear.slice(2)}`;
    } else if (startMonth === endMonth && startYear === endYear) {
      const strMonth = intMonthToStr(startMonth);
      return `${startDay} - ${endDay} ${strMonth} ${startYear.slice(2)}`;
    } else if (startYear === endYear) {
      const strStartMonth = intMonthToStr(startMonth);
      const strEndMonth = intMonthToStr(endMonth);
      return `${startDay} ${strStartMonth} - ${endDay} ${strEndMonth} ${startYear.slice(
        2
      )}`;
    } else {
      const strStartMonth = intMonthToStr(startMonth);
      const strEndMonth = intMonthToStr(endMonth);
      return `${startDay} ${strStartMonth} ${startYear.slice(
        2
      )} - ${endDay} ${strEndMonth} ${endYear.slice(2)}`;
    }
  }
};

export const dateRangeFormattingLongYear = (startDate, endDate) => {
  if (startDate && endDate) {
    startDate = dayjs(startDate).format("DD-MM-YYYY");
    endDate = dayjs(endDate).format("DD-MM-YYYY");
    const [startDay, startMonth, startYear] = startDate.split("-");
    const [endDay, endMonth, endYear] = endDate.split("-");

    if (startDate == endDate) {
      const strMonth = intMonthToStr(startMonth);
      return `${startDay} ${strMonth} ${startYear}`;
    } else if (startMonth === endMonth && startYear === endYear) {
      const strMonth = intMonthToStr(startMonth);
      return `${startDay} - ${endDay} ${strMonth} ${startYear}`;
    } else if (startYear === endYear) {
      const strStartMonth = intMonthToStr(startMonth);
      const strEndMonth = intMonthToStr(endMonth);
      return `${startDay} ${strStartMonth} - ${endDay} ${strEndMonth} ${startYear}`;
    } else {
      const strStartMonth = intMonthToStr(startMonth);
      const strEndMonth = intMonthToStr(endMonth);
      return `${startDay} ${strStartMonth} ${startYear} - ${endDay} ${strEndMonth} ${endYear}`;
    }
  }
};

export const dateMinusMonths = (date, months) => {
  if (date) {
    date = dayjs(date).subtract(months, "month");
    date = dashDateToNiceDate(date);

    return date;
  }
};

export const dateMinusDays = (date, days) => {
  if (date) {
    date = dayjs(date).subtract(days, "day");
    date = dashDateToNiceDate(date);

    return date;
  }
};

export const dateDiffMilliSeconds = (date1, date2) => {
  date1 = dayjs(date1);
  date2 = dayjs(date2);
  const milliseconds = Math.abs(date1.diff(date2));
  return milliseconds;
};

export const getAge = (birthday, lowerBoundaryAge) => {
  const ageBasedOnYear = dayjs().year() - dayjs(birthday).year();
  const ageBasedOnBirthday = dayjs().diff(dayjs(birthday), "year");

  if (ageBasedOnBirthday === lowerBoundaryAge - 1) {
    return ageBasedOnYear;
  }

  return ageBasedOnBirthday;
};

export const getDateForNextDay = (day) => {
  // 1 is monday, 2 is tuesday, etc
  const dateCopy = new Date();
  const nextDate = new Date(
    dateCopy.setDate(
      dateCopy.getDate() + ((7 - dateCopy.getDay() + day) % 7 || 7)
    )
  );

  return nextDate;
};

export const userAgeEligibleForTrip = (birthday, age_restriction) => {
  let [startAge, endAge] = age_restriction.split("-");
  startAge = parseInt(startAge);
  endAge = parseInt(endAge);
  const age = getAge(birthday, startAge);

  if (startAge <= age && age <= endAge) {
    return true;
  } else {
    return false;
  }
};

export const userReservedPaymentForTrip = async (userId, tripId) => {
  try {
    const res = await axios.get("/v1/trip/getReserved", {
      params: { userId: userId, tripId: tripId },
    });

    return res.data;
  } catch (err) {
    return {
      isReserved: false,
      bookingPosition: -1,
    };
  }
};

export const objectEmpty = (obj) => {
  return Object.keys(obj).length === 0;
};

export const isNumber = (value) => {
  return !isNaN(parseFloat(value)) && isFinite(value);
};

// Replace each tab with 2 spaces
export const replaceTabsWithSpaces = (str) => {
  return str.replace(/\t/g, "  ");
};

export const earlyBirdSlotsRemaining = (
  num_slots_paid,
  total_slots,
  early_bird_percentage = 25,
  late_bird_percentage = 25
) => {
  const earlySpots = Math.ceil((total_slots * early_bird_percentage) / 100);
  if (num_slots_paid / total_slots < early_bird_percentage / 100) {
    return earlySpots - num_slots_paid;
  }

  // considered late when left with X% of slots
  const numOfLateSlots = Math.floor((late_bird_percentage / 100) * total_slots);
  const numOfEarlySlots = total_slots - numOfLateSlots;
  const numOfEarlySlotsRemaining = numOfEarlySlots - num_slots_paid;

  return numOfEarlySlotsRemaining > 0 ? numOfEarlySlotsRemaining : 0;
};

export const cloneDeep = (obj) => {
  return JSON.parse(JSON.stringify(obj));
};

/**
 * Navigates back one page if there is a page history, otherwise navigates to the specified fallback path.
 *
 * @param {Function} navigate - The navigation function (e.g., from Gatsby or React Router).
 * @param {string} fallbackPath - The fallback path to navigate to if there is no page history.
 */
export const goBackOrNavigate = (navigate, fallbackPath) => {
  if (window.history.length > 1) {
    navigate(-1);
  } else {
    navigate(fallbackPath);
  }
};

export const isEmptyObject = (obj) => !obj || Object.keys(obj).length === 0;

export const removeDuplicates = (array) => {
  return [...new Set(array)];
};

export const arrayToDict = (array, keyProp, value) => {
  const dict = {};
  for (const obj of array) {
    dict[obj[keyProp]] = value;
  }
  return dict;
};

export const objectToQueryParams = (obj) => {
  if (!obj) {
    return "";
  }

  return Object.keys(obj)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(obj[key]))
    .join("&");
};

export const resizeImageFn = async (file, maxSide) => {
  const compress = new Compress();

  const resizedImage = await compress.compress([file], {
    size: 1, // the max size in MB, defaults to 2MB
    quality: 1, // the quality of the image, max is 1,
    maxWidth: maxSide ?? 512, // the max width of the output image, defaults to 1920px
    maxHeight: maxSide ?? 512, // the max height of the output image, defaults to 1920px
    resize: true, // defaults to true, set false if you do not want to resize the image width and height
  });

  const img = resizedImage[0];
  const base64str = img.data;
  const imgExt = img.ext;
  const resizedBlob = Compress.convertBase64ToFile(base64str, imgExt);
  return resizedBlob;
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const makeAppLink = (url, params) => {
  //need to replace sotravel with exp+sotravelapp-dev for local dev
  const slug = isDev ? "exp+sotravelapp-dev" : "sotravel";
  let link = `${slug}://${url}`;
  if (params && typeof params === "object") {
    link += "?";
    Object.entries(params).forEach(([k, v], i) => {
      if (i !== 0) link += "&";
      link += `${k}=${v}`;
    });
  }
  return link;
};

// example input is "1400"
export const convertMilitaryToCivilianTime = (
  militaryTime,
  offsetMinutes = 0
) => {
  const hours = militaryTime.slice(0, 2);
  const minutes = militaryTime.slice(2);
  let formattedHours = Number(hours);
  let formattedMinutes = Number(minutes);

  // Convert offsetMinutes to a proper date offset
  const offsetDate = new Date(0, 0, 0, formattedHours, formattedMinutes);
  offsetDate.setMinutes(offsetDate.getMinutes() + offsetMinutes);

  // Extract the updated hours and minutes
  formattedHours = offsetDate.getHours();
  formattedMinutes = offsetDate.getMinutes();

  // Determine the period
  const period = formattedHours >= 12 ? "pm" : "am";

  // Convert to 12-hour format
  formattedHours = ((formattedHours + 11) % 12) + 1;

  const formattedMinutesString = formattedMinutes.toString().padStart(2, "0");

  return `${formattedHours}:${formattedMinutesString} ${period}`;
};

export const calculateMilitaryTimeDifference = (startTime, endTime) => {
  // Convert military times to minutes since midnight
  const startHours = parseInt(startTime.slice(0, 2));
  const startMinutes = parseInt(startTime.slice(2));
  const endHours = parseInt(endTime.slice(0, 2));
  const endMinutes = parseInt(endTime.slice(2));

  const startTotalMinutes = startHours * 60 + startMinutes;
  const endTotalMinutes = endHours * 60 + endMinutes;

  // Calculate difference in minutes
  const diffMinutes = endTotalMinutes - startTotalMinutes;

  // Convert to hours and remaining minutes
  const hours = Math.floor(diffMinutes / 60);
  const minutes = diffMinutes % 60;

  // Handle grammar for different cases
  if (hours === 0) {
    return `${minutes} minute${minutes !== 1 ? "s" : ""}`;
  } else if (minutes === 0) {
    return `${hours} hour${hours !== 1 ? "s" : ""}`;
  } else {
    return `${hours} hour${hours !== 1 ? "s" : ""} and ${minutes} minute${
      minutes !== 1 ? "s" : ""
    }`;
  }
};

export const resizeImage = (file, width, height, quality) => {
  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      file, // Is the file of the image which will resized.
      width, // Is the maxWidth of the resized new image.
      height, // Is the maxHeight of the resized new image.
      "WEBP", // Is the compressFormat of the resized new image.
      quality, // Is the quality of the resized new image 0-100
      0, // Is the degree of clockwise rotation to apply to uploaded image.
      (uri) => {
        resolve(uri);
      }, // Is the callBack function of the resized new image URI.
      "base64", // Is the output type of the resized new image.
      width, // Is the minWidth of the resized new image.
      height // Is the minHeight of the resized new image.
    );
  });
};

export const getNextFridayDate = () => {
  const today = new Date();
  const dayOfWeek = today.getDay();
  const daysUntilNextFriday =
    dayOfWeek === 5 ? 7 : dayOfWeek < 5 ? 5 - dayOfWeek : 5 + (7 - dayOfWeek);
  const nextFriday = new Date(today);
  nextFriday.setDate(today.getDate() + daysUntilNextFriday);
  const nextFridayFormatted = nextFriday.toISOString().split("T")[0];
  return nextFridayFormatted;
};
