import React, { useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import "./index.scss";
import ExpandCircleDownOutlinedIcon from "@mui/icons-material/ExpandCircleDownOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { Stack } from "@mui/system";
import { DateWrapper } from "./DateWrapper";
import { LocationWrapper } from "./LocationWrapper";
import Dialog from "@mui/material/Dialog";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

export const DateLocationWrapperMobile = ({
  selectedDates,
  handleSelectDates,
  selectedRegions,
  handleSelectRegions,
  searchMobile,
  handleCloseSearchMobile,
  handleSelectMonths,
  handleSearch,
  handleClearSelections,
  dates,
}) => {
  const [currentFocus, setCurrentFocus] = useState("dates"); // dates or regions
  const handleChangeFocus = (focus) => {
    setCurrentFocus(focus);
  };

  return (
    <Dialog
      open={searchMobile}
      onClose={handleCloseSearchMobile}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      fullScreen
      PaperProps={{
        style: {
          backgroundColor: "#F7F7F7",
        },
      }}
    >
      <div
        style={{
          backgroundColor: "F7F7F7",
          display: "flex",
          position: "fixed",
          top: 0,
          width: "100%",
          height: "3.5rem",
          opacity: 0.99,
          zIndex: 100,
        }}
      >
        <button
          style={{
            height: "2.5rem",
            width: "2.5rem",
            border: "1px solid darkgrey",
            borderRadius: "50%",
            margin: "1rem",
            marginTop: "1.5rem",
          }}
          onClick={handleCloseSearchMobile}
        >
          <p style={{ fontSize: "18px", fontWeight: "bold" }}>x</p>
        </button>
      </div>
      <div className="MobileDateLocationChoiceWrapper">
        <div
          className="MobileDateLocationChoiceContainer"
          onClick={() => handleChangeFocus("dates")}
        >
          {currentFocus === "dates" ? (
            <DateWrapper
              selectedDates={selectedDates}
              handleSelectDates={handleSelectDates}
              handleSelectMonths={handleSelectMonths}
              dates={dates}
            />
          ) : (
            <div className="DateSummaryContainer">
              <p style={{ color: "grey" }}>When</p>
              {selectedDates.length === 1 ? (
                <p>{selectedDates[0]}</p>
              ) : selectedDates.length > 1 ? (
                <p>{selectedDates[0]} & more</p>
              ) : (
                <p></p>
              )}
            </div>
          )}
        </div>

        <div
          className="MobileDateLocationChoiceContainer"
          onClick={() => handleChangeFocus("regions")}
        >
          {currentFocus === "regions" ? (
            <LocationWrapper
              selectedRegions={selectedRegions}
              handleSelectRegions={handleSelectRegions}
            />
          ) : (
            <div className="DateSummaryContainer">
              <p style={{ color: "grey" }}>Where</p>
              {selectedRegions.length === 1 ? (
                <p>{selectedRegions[0]}</p>
              ) : selectedRegions.length > 1 ? (
                <p>{selectedRegions[0]} & more</p>
              ) : (
                <p></p>
              )}
            </div>
          )}
        </div>

        <Stack
          width="100%"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          position="absolute"
          style={{
            bottom: "0",
            backgroundColor: "white",
            height: "5rem",
            borderTop: "1px solid rgb(226, 226, 226)",
          }}
        >
          <button onClick={handleClearSelections}>
            <span
              style={{
                marginLeft: "20px",
                fontWeight: 900,
                textDecoration: "underline",
              }}
            >
              Clear all
            </span>
          </button>
          <button
            className="DateLocationSearchButton"
            onClick={() => {
              handleSearch();
              handleCloseSearchMobile();
            }}
            style={{ marginRight: "20px" }}
          >
            <SearchOutlinedIcon className="DateLocationSearchButtonIcon" />
            <span style={{ marginLeft: "10px" }}>Search</span>
          </button>
        </Stack>
      </div>{" "}
    </Dialog>
  );
};
