import React from "react";
import { CircularProgress, Box } from "@mui/material";

const LoadingView = ({ height, showHoldMessage = false }) => {
  return (
    <Box
      className="LoadingView"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height={"100vh" || height}
      width="100%"
      position="absolute"
      zIndex={1}
    >
      <CircularProgress />
      {showHoldMessage && <p style={{ marginTop: "1rem" }}>Please hold..</p>}
    </Box>
  );
};

export default LoadingView;
