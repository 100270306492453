import axios from "axios";

let instance;
if (process.env.NODE_ENV === "development") {
  instance = new axios.create({
    baseURL: process.env.REACT_APP_BASE_URL || "http://localhost:8080/",
  });
} else {
  instance = new axios.create({
    baseURL: "https://api.sotravel.me/",
  });
}

instance.interceptors.request.use((config) => {
  if (process.env.NODE_ENV === "development")
    config.headers.set("ngrok-skip-browser-warning", 1);
  return config;
});

export const unauthAxiosFetcher = async (url) => {
  try {
    const response = await instance.get(url);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error); // Log the error
    throw error;
  }
};

export default instance;
