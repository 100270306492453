import * as React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Snackbar, { snackbarClasses } from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { useGlobalState } from "../../GlobalStates";
import { isMobile } from "react-device-detect";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const customStyle = {
  backgroundColor: "deepskyblue", // Change the background color
  color: "white", // Change the text color
  fontFamily: "Manrope", // Change the font family
  width: isMobile ? "97%" : "15rem",
};

export const AlertBanner = () => {
  const [openAlertBannerMsg, setOpenAlertBannerMsg] =
    useGlobalState("openAlertBannerMsg");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlertBannerMsg("");
  };

  return (
    <Snackbar
      open={openAlertBannerMsg}
      autoHideDuration={3000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <Alert sx={customStyle} severity="info">
        {openAlertBannerMsg}
      </Alert>
    </Snackbar>
  );
};
