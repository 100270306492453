import { useLocation } from 'react-router-dom';

/**
 * A custom React hook for parsing query parameters from the current URL.
 *
 * @returns {URLSearchParams} An instance of URLSearchParams containing the parsed query parameters.
 *
 * @example
 * import { useQueryParams } from './useQueryParams';
 *
 * function MyComponent() {
 *   const queryParams = useQueryParams();
 *   const param1 = queryParams.get('param1');
 *   const param2 = queryParams.get('param2');
 *
 *   return (
 *     <div>
 *       <p>Param1: {param1}</p>
 *       <p>Param2: {param2}</p>
 *     </div>
 *   );
 * }
 */

const useQueryParams = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  return queryParams;
}

export default useQueryParams;