import React from "react";
import { isMobile } from "react-device-detect";

export const DateWrapper = ({
  selectedDates,
  handleSelectDates,
  handleSelectMonths,
  dates,
}) => {
  return (
    <div className="DateContainer">
      {isMobile ? (
        <p style={{ fontWeight: "800", fontSize: "20px" }}>When's your trip?</p>
      ) : (
        <p style={{ fontWeight: "800" }}>Dates</p>
      )}
      <div className="DatesBox">
        {dates.map((date, key) => (
          <button
            className={`DateBox ${
              selectedDates.includes(date) ? "selected" : ""
            }`}
            onClick={() => {
              handleSelectDates(date);
              handleSelectMonths(key + 1);
            }}
          >
            {date}
          </button>
        ))}
      </div>
    </div>
  );
};
