import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React, { useEffect, useRef } from "react";
import TagManager from "react-gtm-module";
import { lazily } from "react-lazily";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.scss";
import { useGlobalState } from "./GlobalStates";
import { Header } from "./components/header/header";
import ScrollToTop from "./customHooks/useScrollToTop";
import { getUserData } from "./services/user";
import DownloadAppFooter from "./components/layout/DownloadAppFooter";
import ReactGA from "react-ga4";

import { LoginWidget } from "./components/newLogin/LoginWidget";
import { LoginModal } from "./components/newLogin/LoginModal";

import ScrollReveal from "./utils/ScrollReveal";
import axios from "./axios";

import posthog from "posthog-js";

import { AlertBanner } from "./components/elements/AlertBanner";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
let stripePromise;
if (process.env.NODE_ENV === "development") {
  stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
} else {
  stripePromise = loadStripe(
    "pk_live_51MRH4CE4o8VKdOZtATQVHtkVABiD2IGb7g4DdjCdKH9xH8VgG1HRXXvtlkVVovIzQpJp8BNmdW70SenNZkRJxBmX00UOIOtSW7"
  );
}

const Landing = React.lazy(() => import("./intro/Landing"));
const WelcomeDiscount = React.lazy(() => import("./intro/WelcomeDiscount"));
const CommunityPage = React.lazy(() => import("./intro/CommunityPage"));

const UserProfile = React.lazy(() => import("./profile/UserProfile"));
const MyTripActions = React.lazy(() => import("./myTrips/MyTripActions"));
const MyActivityActions = React.lazy(() =>
  import("./myActivities/MyActivityActions")
);
const MyTripUpload = React.lazy(() => import("./myTrips/MyTripUpload"));
const MyTripItinerary = React.lazy(() => import("./myTrips/MyTripItinerary"));
const MyTripReview = React.lazy(() => import("./myTrips/MyTripReview"));
const MyTripItinerarySubpage = React.lazy(() =>
  import("./myTrips/MyTripItinerarySubpage")
);
// Using lazily for named imports
const { Home } = lazily(() => import("./home/Home"));
const { About } = lazily(() => import("./home/About"));
const { Trip } = lazily(() => import("./pages/trip/Trip"));
const { Book } = lazily(() => import("./pages/reserve/Book"));
const { Event } = lazily(() => import("./pages/event/Event"));
const { SoclubBook } = lazily(() => import("./pages/reserve/SoclubBook"));

const { Confirm } = lazily(() => import("./pages/booked/Confirm"));
const { ConfirmEventBooking } = lazily(() =>
  import("./pages/booked/ConfirmEventBooking")
);
const { Refund } = lazily(() => import("./pages/booked/Refund"));
const { ProjectS } = lazily(() => import("./projectS/ProjectS"));
const { DoMore } = lazily(() => import("./projectS/DoMore"));

const { LoginPage } = lazily(() => import("./components/login/LoginPage"));
const { AppTelegramLogin } = lazily(() =>
  import("./components/login/AppTelegramLogin")
);

//AppLinks
const LinkToAppExplore = React.lazy(() =>
  import("./pages/appLinks/LinkToAppExplore")
);
const LinkToAppSoclub = React.lazy(() =>
  import("./pages/appLinks/LinkToAppSoclub")
);
const LinkToAppChat = React.lazy(() =>
  import("./pages/appLinks/LinkToAppChat")
);
const LinkToAppTripDetails = React.lazy(() =>
  import("./pages/appLinks/LinkToAppTripDetails")
);
const LinkToAppSoclubUserActivityDetails = React.lazy(() =>
  import("./pages/appLinks/LinkToAppSoclubUserActivityDetails")
);
const LinkToAppMerchantBookings = React.lazy(() =>
  import("./pages/appLinks/LinkToAppMerchantBookings")
);

const { LoginForAccess } = lazily(() =>
  import("./components/loginForAccess/LoginForAccess")
);
const { ClaimDiscount } = lazily(() =>
  import("./components/loginForAccess/ClaimDiscount")
);

const { Profile } = lazily(() => import("./profile/Profile"));
const { NotifEdit } = lazily(() => import("./profile/NotifEdit"));
const { Policy } = lazily(() => import("./components/privacy-policy/Policy"));
const { PolicyApp } = lazily(() =>
  import("./components/privacy-policy/PolicyApp")
);
const { TermsApp } = lazily(() =>
  import("./components/privacy-policy/TermsApp")
);

const { MyTrips } = lazily(() => import("./myTrips/MyTrips"));

const { HostForm } = lazily(() => import("./pages/host/HostForm"));
const { HostReviewForm } = lazily(() =>
  import("./pages/host/HostReview/HostReviewForm")
);
const { HostReviewStatus } = lazily(() =>
  import("./pages/host/HostReview/HostReviewStatus")
);

if (process.env.REACT_APP_GTM_ID) {
  TagManager.initialize({ gtmId: process.env.REACT_APP_GTM_ID });
}

function App() {
  if (process.env.NODE_ENV === "development") {
    // init dev stuff
  } else {
    // clarity.init("mmnx5isd28");
    posthog.init("phc_yqvnkXnyjUZacqkVtAbWUGf9TB4ghnziU0CwiM37VMH", {
      api_host: "https://us.i.posthog.com",
      person_profiles: "identified_only", // or 'always' to create profiles for anonymous users as well
    });
  }

  const [user, setUser] = useGlobalState("user");
  const [, setUserLoading] = useGlobalState("userLoading");
  const [promotion, setPromotion] = useGlobalState("promotion");

  const scrollRevealRef = useRef(null);

  // User sign in
  useEffect(() => {
    // Set dev user id and jwt token for convenience

    if (process.env.NODE_ENV === "development") {
      // process.env.REACT_APP_DEV_USER_ID
      //   ? localStorage.setItem("USER_ID", process.env.REACT_APP_DEV_USER_ID)
      //   : localStorage.removeItem("USER_ID");
      // process.env.REACT_APP_DEV_JWT_TOKEN
      //   ? localStorage.setItem("JWT_TOKEN", process.env.REACT_APP_DEV_JWT_TOKEN)
      //   : localStorage.removeItem("JWT_TOKEN");
      process.env.REACT_APP_DEV_USER_ID &&
        localStorage.setItem("USER_ID", process.env.REACT_APP_DEV_USER_ID);
      process.env.REACT_APP_DEV_JWT_TOKEN &&
        localStorage.setItem("JWT_TOKEN", process.env.REACT_APP_DEV_JWT_TOKEN);
    }
    const getUser = async () => {
      let jwt_token = localStorage.getItem("JWT_TOKEN");
      if (jwt_token) {
        try {
          const res = await getUserData();
          setUser(res.data);
          posthog.identify(res.data.id, {
            email: res.data.email,
            name: res.data.first_name,
          });
        } catch (err) {
          console.log("user id does not exist", err);
        }
      }
      setUserLoading(false);
    };

    document.body.classList.add("is-loaded");
    scrollRevealRef.current.init();
    getUser();
  }, [setUser, setUserLoading]);

  useEffect(() => {
    const getCurrentPromotions = async () => {
      const res = await axios.get(
        `/v1/trip/getOngoingPromotionsForTrips?user_id=${user?.id ?? ""}`
      );
      if (res.data) {
        setPromotion(res.data);
      }
    };

    getCurrentPromotions();
  }, [user]);

  useEffect(() => {
    // init Google Analytics
    ReactGA.initialize([
      {
        trackingId: "G-XL60GVTDED",
      },
    ]);

    scrollRevealRef.current.init();
  }, [window.location.href]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ScrollReveal
        ref={scrollRevealRef}
        children={() => (
          <Router>
            <ScrollToTop />
            <AlertBanner />
            <Header />
            <DownloadAppFooter />
            <LoginWidget />
            <LoginModal />
            <Routes>
              <Route path="/" exact element={<Landing />} />
              <Route
                path="/welcome-discount"
                exact
                element={<WelcomeDiscount />}
              />
              <Route path="/community-page" exact element={<CommunityPage />} />
              <Route path="/claim-discount" exact element={<ClaimDiscount />} />

              <Route path="/all-trips" exact element={<Home />} />

              <Route path="/about" element={<About />} />

              <Route path="/t/:project_slug" element={<Trip />} />
              <Route path="/trip/:trip_id" element={<Trip />} />
              <Route path="/trips/:project_id" element={<Trip />} />

              <Route path="/host/:host_trip_id" element={<Trip />} />
              <Route path="/reserve" element={<Book />} />
              <Route path="/booked" element={<Confirm />} />
              <Route path="/cancellation" element={<Refund />} />

              <Route path="/club/:activity_id" element={<Event />} />
              <Route path="/soclub/reserve" element={<SoclubBook />} />
              <Route path="/soclub/booked" element={<ConfirmEventBooking />} />

              <Route path="/login" element={<LoginPage />} />

              <Route path="/app-login" element={<AppTelegramLogin />} />

              {/* https://sotravel.me/app-redirect/Explore/trip/141
              https://sotravel.me/app-redirect/Explore/trips/1 */}
              <Route
                path="/app-redirect/Explore/*"
                element={<LinkToAppExplore />}
              />

              <Route
                path="/app-redirect/Soclub/:merchantStoreId?"
                element={<LinkToAppSoclub />}
              />
              {/* reserved booking trip page */}
              <Route
                path="/app-redirect/trip/:tripId?"
                element={<LinkToAppTripDetails />}
              />
              <Route
                path="/app-redirect/trip/:tripId?/updateTripInformation"
                element={
                  <LinkToAppTripDetails subUrl={"updateTripInformation"} />
                }
              />
              <Route
                path="/app-redirect/trip/:tripId?/tripInstructions"
                element={<LinkToAppTripDetails subUrl={"tripInstructions"} />}
              />
              <Route
                path="/app-redirect/trip/:tripId?/viewRoomies"
                element={<LinkToAppTripDetails subUrl={"viewRoomies"} />}
              />
              <Route
                path="/app-redirect/trip/:tripId?/whosComing"
                element={<LinkToAppTripDetails subUrl={"whosComing"} />}
              />
              <Route
                path="/app-redirect/trip/:tripId?/settlePayments"
                element={<LinkToAppTripDetails subUrl={"settlePayments"} />}
              />
              <Route
                path="/app-redirect/trip/:tripId?/feedback"
                element={<LinkToAppTripDetails subUrl={"feedback"} />}
              />
              <Route
                path="/app-redirect/SoclubUserActivityDetails/:activityId?"
                element={<LinkToAppSoclubUserActivityDetails />}
              />
              <Route
                path="/app-redirect/chat/:chatId?"
                element={<LinkToAppChat />}
              />
              <Route
                path="/app-redirect/event_chat/:chatId?"
                element={<LinkToAppChat isEventChat={true} />}
              />
              <Route
                path="/app-redirect/soclub_chat/:chatId?"
                element={<LinkToAppChat isSoclubChat={true} />}
              />

              <Route
                path="/app-redirect/MerchantBookings"
                element={<LinkToAppMerchantBookings />}
              />

              <Route path="/access" element={<LoginForAccess />} />

              <Route path="/profile" element={<Profile />} />
              <Route path="/notif-edit" element={<NotifEdit />} />
              <Route path="/profile/:user_id" element={<UserProfile />} />
              <Route path="/project-s" element={<ProjectS />} />
              <Route path="/domore" element={<DoMore />} />

              <Route path="/my-trips" exact element={<MyTrips />} />
              <Route
                path="/my-trips/:trip_id/action-items"
                exact
                element={
                  <Elements stripe={stripePromise}>
                    <MyTripActions />
                  </Elements>
                }
              />
              <Route
                path="/my-activities/:activity_id/action-items"
                exact
                element={
                  <Elements stripe={stripePromise}>
                    <MyActivityActions />
                  </Elements>
                }
              />
              <Route
                path="/my-trips/:trip_id/upload"
                exact
                element={<MyTripUpload />}
              />
              <Route
                path="/my-projects/:project_id/upload"
                exact
                element={<MyTripUpload />}
              />

              <Route
                path="/my-trips/:trip_id/itinerary"
                exact
                element={<MyTripItinerary />}
              />
              <Route
                path="/my-trips/:trip_id/review"
                exact
                element={<MyTripReview />}
              />
              <Route
                path="/my-trips/:trip_id/itinerary/:notion_id"
                exact
                element={<MyTripItinerarySubpage />}
              />

              <Route path="/trip-starter-form" element={<HostForm />} />
              <Route
                path="/user-review-status"
                exact
                element={<HostReviewStatus />}
              />
              <Route
                path="/add-review/:tele_username"
                exact
                element={<HostReviewForm />}
              />

              <Route path="/privacy-policy" element={<Policy />} />
              <Route path="/privacy-policy-app" element={<PolicyApp />} />
              <Route path="/terms-app" element={<TermsApp />} />

              <Route element={<Home />} />
            </Routes>
          </Router>
        )}
      />
    </LocalizationProvider>
  );
}

export default App;
