import React from "react";
import { useGlobalState } from "../../GlobalStates";
import { isMobile } from "react-device-detect";
import Button from "@mui/material/Button";
import { useLocation } from "react-router-dom";
import { ConstructionOutlined } from "@mui/icons-material";

export const LoginWidget = ({}) => {
  const isInApp = window.isInApp;
  const location = useLocation();

  const [user, setUser] = useGlobalState("user");
  const [userLoading] = useGlobalState("userLoading");
  const [openLogin, setOpenLogin] = useGlobalState("openLogin");

  const shouldShowWelcomeGift = () => {
    if (
      (location.pathname == "/" ||
        location.pathname.toLowerCase().includes("/all-trips")) &&
      !isInApp &&
      !userLoading &&
      !user?.email
    ) {
      return true;
    }
  };

  if (shouldShowWelcomeGift())
    return (
      <Button
        onClick={() => {
          setOpenLogin(true);
        }}
        style={
          isMobile
            ? {
                height: "4rem",
                width: "4rem",
                borderRadius: "50%",
                zIndex: 1000,
                position: "fixed",
                bottom: 0,
                left: 0,
                margin: "1.5rem",
                color: "white",
              }
            : {
                height: "4rem",
                width: "4rem",
                borderRadius: "50%",
                zIndex: 1000,
                position: "fixed",
                bottom: 0,
                left: 0,
                margin: "3rem",
              }
        }
      >
        {openLogin ? (
          <img src="/images/welcomeGiftOpen.png" alt="welcomeGiftOpen" />
        ) : (
          <img src="/images/welcomeGift.png" alt="welcomeGift" />
        )}
      </Button>
    );
};
