import React, { useState } from "react";
import { useGlobalState } from "../../GlobalStates";
import axios from "../../axios";
import { countries_phone_extension } from "../../data/countries";
import { TextField, Stack } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";

import {
  sendWhatsappOTPForLogin,
  loginWithWhatsapp,
} from "../../services/user";

export const WhatsappLoginWidget = ({ handleOnLogin }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [loginJourney, setLoginJourney] = useState("send_otp"); // send_otp, verify_otp

  const [user, setUser] = useGlobalState("user");

  const [OTP, setOTP] = useState("");
  const [phoneInfo, setPhoneInfo] = useState({
    phone_extension: "+65",
    phone_number: "",
  });
  const handlePhoneInfoChange = (key, value) => {
    setPhoneInfo((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleSendOTP = async () => {
    try {
      const res = await sendWhatsappOTPForLogin(
        phoneInfo.phone_extension,
        phoneInfo.phone_number
      );

      if (res.status == 200) {
        setLoginJourney("verify_otp");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleVerifyOTP = async () => {
    try {
      const res = await loginWithWhatsapp(
        phoneInfo.phone_extension,
        phoneInfo.phone_number,
        OTP
      );

      if (res.status == 200) {
        localStorage.setItem("USER_ID", res.data.user.id);
        localStorage.setItem("JWT_TOKEN", res.data.token);
        setUser(res.data.user);
        handleOnLogin();
      }
    } catch (error) {
      console.log(error);
    }
  };

  if (loginJourney === "send_otp")
    return (
      <Stack flexDirection="colum" width="100%">
        <Stack flexDirection="row" width="100%">
          <TextField
            value={phoneInfo.phone_extension}
            onChange={(e) => {
              handlePhoneInfoChange("phone_extension", e.target.value);
            }}
            select
            label="Extension"
            style={{ width: "35%", marginRight: "5px" }}
          >
            {countries_phone_extension
              .sort((a, b) => a.short_name.localeCompare(b.short_name)) // Sort by country short_name
              .map((country) => (
                <MenuItem key={country.name} value={country.phone_extension}>
                  {country.short_name} ({country.phone_extension})
                </MenuItem>
              ))}
          </TextField>

          <TextField
            key="phone_number"
            type="number"
            style={{ width: "65%" }}
            required
            label="Phone number"
            value={phoneInfo.phone_number}
            onChange={(e) =>
              handlePhoneInfoChange("phone_number", e.target.value)
            }
          />
        </Stack>{" "}
        <Button
          onClick={handleSendOTP}
          disabled={isLoading}
          sx={{
            "&.MuiButtonBase-root:hover": {
              bgcolor: "#33CCFF",
            },
          }}
          style={{
            fontFamily: '"Manrope", sans-serif',
            fontWeight: 700,
            fontSize: "16px",
            color: "white",
            backgroundColor: "dodgerblue",
            textTransform: "unset",
            height: "3rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            marginTop: "1rem",
            borderRadius: "10px",
          }}
        >
          Send OTP
        </Button>
      </Stack>
    );

  if (loginJourney === "verify_otp")
    return (
      <Stack flexDirection="colum" width="100%">
        <Stack flexDirection="row" width="100%">
          <TextField
            key="otp"
            type="number"
            style={{ width: "100%" }}
            label="6-digit code from Whatsapp"
            value={OTP}
            onChange={(e) => setOTP(e.target.value)}
          />
        </Stack>
        <Button
          onClick={handleVerifyOTP}
          disabled={isLoading}
          sx={{
            "&.MuiButtonBase-root:hover": {
              bgcolor: "#33CCFF",
            },
          }}
          style={{
            fontFamily: '"Manrope", sans-serif',
            fontWeight: 700,
            fontSize: "16px",
            color: "white",
            backgroundColor: "dodgerblue",
            textTransform: "unset",
            height: "3rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            marginTop: "1rem",
            borderRadius: "10px",
          }}
        >
          Submit
        </Button>
      </Stack>
    );
};
