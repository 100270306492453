import React, { useState, useEffect } from "react";
import { MenuButton } from "./MenuButton";
import { useNavigate, useLocation } from "react-router-dom";
import { useGlobalState } from "../../GlobalStates";
import { useWindowSize } from "../../customHooks/useWindowSize";
import { isMobile } from "react-device-detect";
import queryString from "query-string";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import axios from "../../axios";
import { isNumber } from "../../utils/utils";
import PropaneTankOutlinedIcon from "@mui/icons-material/PropaneTankOutlined";
import AcUnitOutlinedIcon from "@mui/icons-material/AcUnitOutlined";
import ForestOutlinedIcon from "@mui/icons-material/ForestOutlined";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import PublicIcon from "@mui/icons-material/Public";
import WaterOutlinedIcon from "@mui/icons-material/WaterOutlined";
import SavingsOutlinedIcon from "@mui/icons-material/SavingsOutlined";
import { DateLocationWrapper } from "./DateLocationWrapper/DateLocationWrapper";
import { DateLocationWrapperMobile } from "./DateLocationWrapper/DateLocationWrapperMobile";
import PriceCheckOutlinedIcon from "@mui/icons-material/PriceCheckOutlined";
import Popover from "@mui/material/Popover";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import useQueryParams from "../../customHooks/useQueryParams";
import { useDidMountEffect } from "../../customHooks/useDidMountEffect";

import "./header.scss";

export const Header = () => {
  const size = useWindowSize();
  const params = useQueryParams();
  const isInApp = window.isInApp;

  const monthParam = params.get("months");
  const continentsParam = params.get("continents");

  const navigate = useNavigate();
  const location = useLocation();
  const [sortByHome, setSortByHome] = useGlobalState("sortByHome");
  const [user, setUser] = useGlobalState("user");

  const [innerWidth, setInnerWidth] = useState("71rem");
  const [navigateTo, setNavigateTo] = useState("/all-trips");

  const [partner, setPartner] = useState("singapore");

  const [showHeader, setShowHeader] = useState(true);

  const [searchMobile, setSearchMobile] = useState(false);
  const handleOpenSearchMobile = () => {
    setSearchMobile(true);
  };
  const handleCloseSearchMobile = () => {
    setSearchMobile(false);
  };

  const getLocalPartner = async (url) => {
    let tripId;
    if (
      url === "book" ||
      url === "confirm" ||
      url === "booked" ||
      url === "refund" ||
      url === "reserve"
    ) {
      const query = queryString.parse(location.search);
      tripId = query.trip_id;
    } else if (url === "trips") {
      const params = location.pathname.split("/");
      tripId = params[params.length - 1];
    } else if (url === "trip") {
      const params = location.pathname.split("/");
      tripId = params[params.length - 1];
    }

    // Trip ID should be a number, else we don't fetch
    if (!isNumber(tripId)) {
      return;
    }
  };

  useEffect(() => {
    if (location.pathname === "/") {
      setShowHeader(false);
      setPartner("singapore");
      setNavigateTo("/");
    } else if (
      location.pathname.toLowerCase().includes("project-s") ||
      location.pathname.toLowerCase().includes("login") ||
      location.pathname.toLowerCase().includes("privacy-policy") ||
      location.pathname.toLowerCase().includes("privacy-policy-app") ||
      location.pathname.toLowerCase().includes("terms-app") ||
      location.pathname.toLowerCase().includes("access") ||
      location.pathname.toLowerCase().includes("chat") ||
      (location.pathname.toLowerCase().includes("my-trips") &&
        location.pathname.toLowerCase().includes("action-items")) ||
      (location.pathname.toLowerCase().includes("my-trips") &&
        location.pathname.toLowerCase().includes("itinerary")) ||
      (location.pathname.toLowerCase().includes("my-trips") &&
        location.pathname.toLowerCase().includes("review")) ||
      (location.pathname.toLowerCase().includes("my-trips") &&
        location.pathname.toLowerCase().includes("upload"))
    ) {
      setShowHeader(false);
    } else if (
      location.pathname === "/host" ||
      location.pathname === "/host/" ||
      location.pathname === "/my-trips" ||
      location.pathname === "/my-trips/" ||
      (location.pathname.includes("/all-trips") && location.search !== "")
    ) {
      setPartner("singapore");
      setShowHeader(true);
      setNavigateTo("/all-trips");
      setInnerWidth("93vw");
    } else if (
      location.pathname === "/all-trips" ||
      location.pathname === "/all-trips/"
    ) {
      setPartner("singapore");
      setShowHeader(true);
      setNavigateTo("/");
      setInnerWidth("93vw");
    } else if (location.pathname.toLowerCase().includes("host")) {
      setNavigateTo("/host");
      setShowHeader(true);
      getLocalPartner("trips");
      if (size.width < 1300) {
        setInnerWidth("95vw");
      } else {
        setInnerWidth("72rem");
      }
    } else if (
      location.pathname === "/profile" ||
      location.pathname === "/profile/" ||
      location.pathname === "/notif-edit" ||
      location.pathname === "/notif-edit/"
    ) {
      setPartner("singapore");
      setShowHeader(true);
      setNavigateTo("/all-trips");
      if (size.width < 1300) {
        setInnerWidth("95vw");
      } else {
        setInnerWidth("76rem");
      }
    } else if (
      location.pathname === "/domore" ||
      location.pathname === "/domore/"
    ) {
      setPartner("singapore");
      setShowHeader(true);
      setNavigateTo("/all-trips");
      if (size.width < 1300) {
        setInnerWidth("90vw");
      } else {
        setInnerWidth("82vw");
      }
    } else if (
      location.pathname.toLowerCase().includes("trips") ||
      location.pathname.toLowerCase().includes("trip")
    ) {
      setNavigateTo("/all-trips");
      setShowHeader(true);
      getLocalPartner("trips");
      if (size.width < 1300) {
        setInnerWidth("95vw");
      } else {
        setInnerWidth("72rem");
      }
    } else if (location.pathname.toLowerCase().includes("host")) {
      setNavigateTo("/host");
      setShowHeader(true);
      getLocalPartner("trips");
      if (size.width < 1300) {
        setInnerWidth("95vw");
      } else {
        setInnerWidth("72rem");
      }
    } else if (
      location.pathname.toLowerCase().includes("book") ||
      location.pathname.toLowerCase().includes("reserve")
    ) {
      setNavigateTo("/all-trips");
      getLocalPartner("book");
      setShowHeader(true);
      if (size.width < 1300) {
        setInnerWidth("95vw");
      } else {
        setInnerWidth("76rem");
      }
    } else if (
      location.pathname.toLowerCase().includes("confirm") ||
      location.pathname.toLowerCase().includes("booked") ||
      location.pathname.toLowerCase().includes("refund")
    ) {
      setNavigateTo("/all-trips");
      getLocalPartner("confirm");
      setShowHeader(true);
      if (size.width < 1300) {
        setInnerWidth("95vw");
      } else {
        setInnerWidth("76rem");
      }
    }
  }, [size.width, location.pathname, location.search]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleProfileClick = () => {
    setAnchorEl(null);
    navigate("/profile");
  };

  const dates = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const [selectedMonths, setSelectedMonths] = useState([]);
  const [selectedDates, setSelectedDates] = useState([]);
  const [selectedRegions, setSelectedRegions] = useState([]);
  const handleSelectDates = (date) => {
    if (selectedDates.includes(date)) {
      setSelectedDates(
        selectedDates.filter((selectedDate) => selectedDate !== date)
      );
    } else {
      setSelectedDates([...selectedDates, date]);
    }
  };
  const handleSelectRegions = (region) => {
    if (selectedRegions.includes(region)) {
      setSelectedRegions(
        selectedRegions.filter((selectedRegion) => selectedRegion !== region)
      );
    } else {
      setSelectedRegions([...selectedRegions, region]);
    }
  };
  const handleSelectMonths = (month) => {
    if (selectedMonths.includes(month)) {
      setSelectedMonths(
        selectedMonths.filter((selectedMonth) => selectedMonth !== month)
      );
    } else {
      setSelectedMonths([...selectedMonths, month]);
    }
  };

  // for users that click on filtered link
  const months = params.get("months");
  const continents = params.get("continents");
  useDidMountEffect(() => {
    if (months) {
      const monthsList = months
        .split(",")
        .map((num) => parseInt(num.trim(), 10));

      setSelectedMonths(monthsList);

      const strMonths = [];
      for (const month of monthsList) {
        strMonths.push(dates[month - 1]);
      }

      setSelectedDates(strMonths);
    }

    if (continents) {
      setSelectedRegions(continents.split(","));
    }
  }, [months, continents]);

  const handleSearch = () => {
    const editSelectedRegions = selectedRegions.map((region) =>
      region === "I'm flexible" ? "Flexible" : region
    );

    setSortByHome("dates");

    if (selectedMonths.length > 0 && editSelectedRegions.length > 0) {
      navigate({
        pathname: "/all-trips",
        search: `?months=${selectedMonths}&continents=${editSelectedRegions}`,
      });
      window.scrollTo(0, 0);
    } else if (selectedMonths.length > 0) {
      navigate({
        pathname: "/all-trips",
        search: `?months=${selectedMonths}`,
      });
      window.scrollTo(0, 0);
    } else if (editSelectedRegions.length > 0) {
      navigate({
        pathname: "/all-trips",
        search: `?continents=${editSelectedRegions}`,
      });
      window.scrollTo(0, 0);
    } else {
      navigate({
        pathname: "/all-trips",
      });
      window.scrollTo(0, 0);
    }
  };

  const handleClearSelections = () => {
    setSelectedRegions([]);
    setSelectedDates([]);
    setSelectedMonths([]);
  };

  if (showHeader) {
    return (
      <div className="headerWrapper">
        <header>
          <div
            className="header-inner"
            style={
              isMobile
                ? { width: innerWidth, justifyContent: "center" }
                : { width: innerWidth }
            }
          >
            {!isMobile && (
              <div
                style={{
                  width: "30%",
                  display: "flex",
                  justifyContent: "flex-start",
                }}
              >
                <div className="header-inner-logo">
                  <button
                    className="logo-wrapper"
                    onClick={() => navigate(navigateTo)}
                  >
                    <img
                      src="/new_logo/horizontal_blue.png"
                      style={{ height: "30px", margin: "8px" }}
                    />

                    {/* <span style={{ color: "deepskyblue" }}>sotravel</span>
                    <img
                      src="/images/cancel.png"
                      style={{ height: "15px", margin: "8px" }}
                    />
                    <span
                      style={{
                        color: "black",
                        fontWeight: "500",
                      }}
                    >
                      singapore
                    </span> */}
                  </button>
                </div>
              </div>
            )}

            {location.pathname === "/all-trips" && (
              <PopupState variant="popover" popupId="demo-popup-popover">
                {(popupState) => (
                  <>
                    {isMobile ? (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignContent: "center",
                          paddingTop: "5px",
                          paddingBotom: "4px",
                        }}
                      >
                        <button
                          className="header-search-container"
                          onClick={handleOpenSearchMobile}
                          style={isInApp ? { width: "97%" } : {}}
                        >
                          <div className="header-search-wrapper">
                            <span>Anywhere</span>
                            <HorizontalRuleIcon
                              style={{ transform: "rotate(90deg)" }}
                            />
                            <span style={{ width: "4.9rem" }}>Any month</span>
                            <SearchOutlinedIcon
                              style={{
                                color: "black",
                                position: "absolute",
                                right: "-16px",
                              }}
                            />
                          </div>
                        </button>

                        {!isInApp && (
                          <MenuButton
                            handleClick={handleClick}
                            handleClose={handleClose}
                            anchorEl={anchorEl}
                            handleProfileClick={handleProfileClick}
                            open={open}
                          />
                        )}

                        <DateLocationWrapperMobile
                          selectedDates={selectedDates}
                          handleSelectDates={handleSelectDates}
                          selectedRegions={selectedRegions}
                          handleSelectRegions={handleSelectRegions}
                          searchMobile={searchMobile}
                          handleCloseSearchMobile={handleCloseSearchMobile}
                          handleSelectMonths={handleSelectMonths}
                          handleSearch={handleSearch}
                          handleClearSelections={handleClearSelections}
                          dates={dates}
                        />
                      </div>
                    ) : (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          paddingTop: "5px",
                          paddingBotom: "4px",
                        }}
                        {...bindTrigger(popupState)}
                      >
                        <button className="header-search-container">
                          <div className="header-search-wrapper">
                            <span>Anywhere</span>
                            <HorizontalRuleIcon
                              style={{ transform: "rotate(90deg)" }}
                            />
                            <span style={{ width: "4.9rem" }}>Any month</span>
                            <SearchOutlinedIcon
                              style={{
                                color: "black",
                                position: "absolute",
                                right: "-16px",
                              }}
                            />
                          </div>
                        </button>
                      </div>
                    )}
                    <Popover
                      {...bindPopover(popupState)}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                      PaperProps={{
                        sx: { borderRadius: "30px", marginTop: "10px" },
                      }}
                    >
                      <DateLocationWrapper
                        selectedDates={selectedDates}
                        handleSelectDates={handleSelectDates}
                        selectedRegions={selectedRegions}
                        handleSelectRegions={handleSelectRegions}
                        closePopUp={() => popupState.close()}
                        handleSelectMonths={handleSelectMonths}
                        handleSearch={handleSearch}
                        handleClearSelections={handleClearSelections}
                        dates={dates}
                      />
                    </Popover>
                  </>
                )}
              </PopupState>
            )}

            {!isMobile && (
              <div
                style={{
                  width: "30%",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <MenuButton
                  handleClick={handleClick}
                  handleClose={handleClose}
                  anchorEl={anchorEl}
                  handleProfileClick={handleProfileClick}
                  open={open}
                />
              </div>
            )}
          </div>
        </header>

        {location.pathname === "/all-trips" && (
          <div className="headerFilter">
            <div
              className="headerFilterContainer"
              style={{ width: innerWidth }}
            >
              <button
                className="headerFilterChoiceContainer"
                style={
                  sortByHome === "dates"
                    ? { color: "black" }
                    : { color: "grey" }
                }
                onClick={() => {
                  setSortByHome("dates");

                  let search = "";
                  if (monthParam) {
                    if (search.length === 0) {
                      search += "?";
                    }
                    search += `months=${monthParam}`;
                  }
                  if (continentsParam) {
                    if (search.length === 0) {
                      search += "?";
                    } else {
                      search += "&";
                    }
                    search += `continents=${continentsParam}`;
                  }

                  navigate({
                    pathname: "/all-trips",
                    search: search,
                  });
                  window.scrollTo(0, 0);
                }}
              >
                <CalendarMonthOutlinedIcon />
                <span>Dates</span>
              </button>

              <button
                className="headerFilterChoiceContainer"
                style={
                  sortByHome === "360" ? { color: "black" } : { color: "grey" }
                }
                onClick={() => {
                  setSortByHome("360");
                  window.scrollTo(0, 0);

                  let search = "?broad=360";
                  if (monthParam) {
                    search += `&months=${monthParam}`;
                  }
                  if (continentsParam) {
                    search += `&continents=${continentsParam}`;
                  }

                  navigate({
                    pathname: "/all-trips",
                    search: search,
                  });
                }}
              >
                <PublicIcon />
                <span>360°</span>
              </button>

              <button
                className="headerFilterChoiceContainer"
                style={
                  sortByHome === "snow" ? { color: "black" } : { color: "grey" }
                }
                onClick={() => {
                  setSortByHome("snow");
                  window.scrollTo(0, 0);

                  let search = "?broad=snow";
                  if (monthParam) {
                    search += `&months=${monthParam}`;
                  }
                  if (continentsParam) {
                    search += `&continents=${continentsParam}`;
                  }

                  navigate({
                    pathname: "/all-trips",
                    search: search,
                  });
                }}
              >
                <AcUnitOutlinedIcon />
                <span>Snow</span>
              </button>
              <button
                className="headerFilterChoiceContainer"
                style={
                  sortByHome === "land" ? { color: "black" } : { color: "grey" }
                }
                onClick={() => {
                  setSortByHome("land");
                  window.scrollTo(0, 0);

                  let search = "?broad=land";
                  if (monthParam) {
                    search += `&months=${monthParam}`;
                  }
                  if (continentsParam) {
                    search += `&continents=${continentsParam}`;
                  }

                  navigate({
                    pathname: "/all-trips",
                    search: search,
                  });
                }}
              >
                <ForestOutlinedIcon />
                <span>Hike</span>
              </button>
              <button
                className="headerFilterChoiceContainer"
                style={
                  sortByHome === "surf" ? { color: "black" } : { color: "grey" }
                }
                onClick={() => {
                  setSortByHome("surf");
                  window.scrollTo(0, 0);

                  let search = "?broad=surf";
                  if (monthParam) {
                    search += `&months=${monthParam}`;
                  }
                  if (continentsParam) {
                    search += `&continents=${continentsParam}`;
                  }

                  navigate({
                    pathname: "/all-trips",
                    search: search,
                  });
                }}
              >
                <WaterOutlinedIcon />
                <span>Surf</span>
              </button>
              <button
                className="headerFilterChoiceContainer"
                style={
                  sortByHome === "dive" ? { color: "black" } : { color: "grey" }
                }
                onClick={() => {
                  setSortByHome("dive");
                  window.scrollTo(0, 0);

                  let search = "?broad=dive";
                  if (monthParam) {
                    search += `&months=${monthParam}`;
                  }
                  if (continentsParam) {
                    search += `&continents=${continentsParam}`;
                  }

                  navigate({
                    pathname: "/all-trips",
                    search: search,
                  });
                }}
              >
                <PropaneTankOutlinedIcon />
                <span>Dive</span>
              </button>

              {!isInApp && isMobile && (
                <button
                  className="headerFilterChoiceContainer"
                  style={
                    sortByHome === "club"
                      ? { color: "black" }
                      : { color: "grey" }
                  }
                  onClick={() => {
                    setSortByHome("club");
                    window.scrollTo(0, 0);

                    let search = "?broad=club";
                    if (monthParam) {
                      search += `&months=${monthParam}`;
                    }
                    if (continentsParam) {
                      search += `&continents=${continentsParam}`;
                    }

                    navigate({
                      pathname: "/all-trips",
                      search: search,
                    });
                  }}
                >
                  <FavoriteBorderIcon />
                  <span>Club</span>
                </button>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
};
