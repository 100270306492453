import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { isMobile } from "react-device-detect";
import Slide from "@mui/material/Slide";
import Dialog from "@mui/material/Dialog";
import { useSwipeable } from "react-swipeable";
import CloseIcon from "@mui/icons-material/Close";
import { TextField, Stack } from "@mui/material";
import TelegramLoginButton from "react-telegram-login";
import { telegramBotUsername } from "../../utils/constants";
import axios from "../../axios";
import { useGlobalState } from "../../GlobalStates";
import { WhatsappLoginWidget } from "../../components/login/WhatsappLoginWidget";
import Button from "@mui/material/Button";
import { sendWelcomeGiftEmail } from "../../services/user";

import "./index.scss";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: 380,
  width: isMobile ? "88%" : "30rem",
  bgcolor: "background.paper",
  borderRadius: 5,
  p: 3,
  overflowY: "scroll",
};

export const LoginModal = ({}) => {
  const [openLogin, setOpenLogin] = useGlobalState("openLogin");
  const [user, setUser] = useGlobalState("user");

  const [email, setEmail] = useState("");
  const handleEmailChange = (value) => {
    setEmail(value);
  };
  useEffect(() => {
    if (user && user?.email) {
      setEmail(user.email);
    }
  }, [user]);

  const [emailSent, setEmailSent] = useState(false);
  const handleSendEmail = async () => {
    try {
      setEmailSent(true);
      await sendWelcomeGiftEmail({
        email: email,
      });
      setUser({ ...user, email: email });
    } catch (error) {
      console.log(error);
    }
  };

  const [loginWith, setLoginWith] = useState(null); // telegram, whatsapp
  const handleLoginWith = (newValue) => {
    setLoginWith(newValue);
  };
  const displayLoginType = () => {
    if (loginWith == null) {
      return (
        <>
          <div className="WelcomeGiftLoginButtonWrapper">
            <button
              class="TelegramStyleButton"
              onClick={() => handleLoginWith("telegram")}
            >
              <img class="TelegramStyleButtonIcon" src="/icons/telegram.png" />
              Telegram
            </button>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "5px",
              marginBottom: "5px",
              width: "100%",
            }}
          >
            <hr style={{ width: "44%", borderTop: "1px solid #ccc" }} />
            <span style={{ margin: "0 10px" }}>or</span>
            <hr style={{ width: "44%", borderTop: "1px solid #ccc" }} />
          </div>

          <div className="WelcomeGiftLoginButtonWrapper">
            <button
              class="TelegramStyleButton"
              style={{ backgroundColor: "#28a71a" }}
              onClick={() => handleLoginWith("whatsapp")}
            >
              <img class="TelegramStyleButtonIcon" src="/icons/whatsapp.png" />
              Whatsapp
            </button>
          </div>
        </>
      );
    } else if (loginWith == "telegram") {
      return (
        <div className="WelcomeGiftLoginButtonWrapper">
          <TelegramLoginButton
            botName="sotravel_bot"
            cornerRadius={8}
            buttonSize="large"
            dataOnauth={(data) => handleLoginWithTelegram(data)}
          />
        </div>
      );
    } else if (loginWith == "whatsapp") {
      return (
        <div className="WelcomeGiftLoginButtonWrapper">
          <WhatsappLoginWidget
            handleOnLogin={() => {
              console.log("specific on login actions");
            }}
          />
        </div>
      );
    }
  };

  const handleLoginWithTelegram = async (data) => {
    try {
      const teleRes = await axios.post("/v1/user/telegramSignin", data);

      localStorage.setItem("USER_ID", teleRes.data.user.id);
      localStorage.setItem("JWT_TOKEN", teleRes.data.token);

      setUser(teleRes.data.user);
    } catch (err) {
      alert(err);
      alert("Please try again.");
    }
  };

  return (
    <Modal
      open={openLogin}
      onClose={() => {
        setOpenLogin(false);
        setLoginWith(null);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div
          style={{
            display: "flex",
            position: "fixed",
            top: 0,
            width: "90%",
          }}
        >
          <button
            style={{ height: "4rem" }}
            onClick={() => setOpenLogin(false)}
          >
            <CloseIcon
              style={{
                fontSize: "20px",
                marginLeft: "-3px",
              }}
            />
          </button>
        </div>

        {emailSent || user?.welcome_email_received >= 1 ? (
          <div className="DownloadAppModalWrapper">
            <span className="DownloadAppModalHeader">Gift sent</span>
            <div className="DownloadAppModalmageWrapper">
              You are in! Check your email for your $20 welcome gift.
            </div>
            <img
              src="/images/welcomeGiftOpen.png"
              alt="welcomeGiftOpen"
              style={{ height: "8rem" }}
            />
          </div>
        ) : localStorage.getItem("JWT_TOKEN") ? (
          <div className="DownloadAppModalWrapper">
            <span className="DownloadAppModalHeader">Final step</span>
            <div className="DownloadAppModalmageWrapper">
              Enter your email to receive your $20 welcome gift!
            </div>

            <TextField
              key="email"
              type="email"
              style={{ width: "100%" }}
              required
              label="Email"
              value={email}
              onChange={(e) => handleEmailChange(e.target.value)}
            />
            <Button
              disabled={emailSent}
              onClick={handleSendEmail}
              sx={{
                "&.MuiButtonBase-root:hover": {
                  bgcolor: "#33CCFF",
                },
              }}
              style={{
                fontFamily: '"Manrope", sans-serif',
                fontWeight: 700,
                fontSize: "16px",
                color: "white",
                backgroundColor: "dodgerblue",
                textTransform: "unset",
                height: "3rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                marginTop: "1rem",
                borderRadius: "10px",
              }}
            >
              Claim gift
            </Button>
          </div>
        ) : (
          <div className="DownloadAppModalWrapper">
            <span className="DownloadAppModalHeader">New to Sotravel?!</span>
            <div className="DownloadAppModalmageWrapper">
              Create an account with Telegram or Whatsapp and get a $20 welcome
              gift!
            </div>
            {displayLoginType()}
          </div>
        )}
      </Box>
    </Modal>
  );
};
