import React from "react";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import "./index.scss";

const MenuNavItem = ({ icon, label, onClick, className = "", style }) => {
  return (
    <MenuItem
      style={style}
      onClick={onClick}
      className={`menu-nav-item ${className}`}
    >
      {icon && (
        <ListItemIcon className="menu-nav-item-icon">{icon}</ListItemIcon>
      )}
      <span className="menu-nav-item-label">{label}</span>
    </MenuItem>
  );
};

export default MenuNavItem;
