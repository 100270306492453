import React, { useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import "./index.scss";
import ExpandCircleDownOutlinedIcon from "@mui/icons-material/ExpandCircleDownOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { Stack } from "@mui/system";
import { DateWrapper } from "./DateWrapper";
import { LocationWrapper } from "./LocationWrapper";

export const DateLocationWrapper = ({
  selectedDates,
  handleSelectDates,
  selectedRegions,
  handleSelectRegions,
  handleSelectMonths,
  handleSearch,
  closePopUp,
  handleClearSelections,
  dates,
}) => {
  return (
    <div className="DateLocationChoiceWrapper">
      <div className="DateLocationChoiceContainer">
        <DateWrapper
          selectedDates={selectedDates}
          handleSelectDates={handleSelectDates}
          handleSelectMonths={handleSelectMonths}
          dates={dates}
        />
        <div className="Line"></div>
        <LocationWrapper
          selectedRegions={selectedRegions}
          handleSelectRegions={handleSelectRegions}
        />
      </div>

      <Stack
        width="100%"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        {selectedDates.length > 0 || selectedRegions.length > 0 ? (
          <button onClick={handleClearSelections}>
            <span
              style={{
                marginLeft: "5px",
                fontWeight: 900,
                textDecoration: "underline",
              }}
            >
              Clear all
            </span>
          </button>
        ) : (
          <div></div>
        )}

        <button
          className="DateLocationSearchButton"
          onClick={() => {
            closePopUp();
            handleSearch();
          }}
          style={{ marginTop: "1.2rem" }}
        >
          <SearchOutlinedIcon className="DateLocationSearchButtonIcon" />
          <span style={{ marginLeft: "10px" }}>Search</span>
        </button>
      </Stack>
    </div>
  );
};
