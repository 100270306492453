import axios from "../axios";
import authAxios from "../authAxios";
import unauthAxios from "../axios";
import { useGlobalState } from "../GlobalStates";

export const ensureUserLoggedIn = () =>
  authAxios.get("/v1/user/ensureUserLoggedIn");

export const giveUserWelcomeDiscount = () =>
  authAxios.get("/v1/user/giveUserWelcomeDiscount");

export const getUserData = () => authAxios.get("/v1/user/getUser");

export const getUserById = (user_id) =>
  axios.get("/v1/user/getUserById", {
    params: { user_id: user_id },
  });

export const getUserByTeleUsername = (tele_username) =>
  axios.get("/v1/user/getUserByTeleUsername", {
    params: { tele_username: tele_username },
  });

// Check if user is authenticated, if it's not authAxios will redirect user
export const useAuthGuard = () => {
  const [user, setUser] = useGlobalState("user");
  const [userLoading, setUserLoading] = useGlobalState("userLoading");

  if (!userLoading && !user.id) {
    return authAxios.get("/v1/user/validateUserJwt");
  } else if (!userLoading && !user.birthday) {
    const originalPath = window.location.pathname + window.location.search;
    window.location = `/login?reroute=${originalPath}`;
    return;
  }
};

export const pingLarry = async (trip_name) => {
  await authAxios.get(`/v1/admin/pingLarry?trip_name=${trip_name}`);
  return;
};

export const getUserHostType = async () => {
  const res = await authAxios.get("/v1/user/getUserHostType");
  return res.data;
};

export const updateUser = (update_data) => {
  return authAxios.post("/v1/user/updateUser", update_data);
};

export const updateUserAffiliateCode = (affiliateCode) => {
  return authAxios.post("/v1/user/updateUserAffiliateCode", {
    affiliate_code: affiliateCode,
  });
};

export const updateProfileUserInterestsAndNotifications = (
  interests = [],
  notifications = []
) => {
  return authAxios.post("/v1/user/updateProfileUserInterestsAndNotifications", {
    interests: interests,
    notifications: notifications,
  });
};

export const getUserExtraMedia = (user_id) => {
  return unauthAxios.get(`/v1/user/getUserExtraMedia?user_id=${user_id}`);
};

export const updateUserExtraMedia = (data) => {
  return authAxios.post("/v1/user/updateUserExtraMedia", data);
};

export const getUserReviews = (user_id) => {
  return unauthAxios.get("/v1/user/getUserReviews", { params: { user_id } });
};

export const upsertUserReview = (review_data) => {
  return authAxios.post("/v1/user/upsertUserReview", review_data);
};

export const sendWhatsappOTPForLogin = (ext, number) => {
  return unauthAxios.post("/v1/user/sendWhatsappOTPForLogin", {
    ext,
    number,
  });
};

export const loginWithWhatsapp = (ext, number, otp, user) => {
  return unauthAxios.post("/v1/user/loginWithWhatsapp", {
    ext: ext,
    number: number,
    otp: otp,
    teleUser: user,
  });
};

export const updateUserPersonalInfo = (data) => {
  return authAxios.post("/v1/user/updateUserPersonalInfo", data);
};

export const sendWelcomeGiftEmail = (data) => {
  return authAxios.post("/v1/user/sendWelcomeGiftEmail", data);
};
