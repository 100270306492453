export const countries_phone_extension = [
  {
    name: "Afghanistan",
    phone_extension: "+93",
    short_name: "AF",
  },
  {
    name: "Albania",
    phone_extension: "+355",
    short_name: "AL",
  },
  {
    name: "Algeria",
    phone_extension: "+213",
    short_name: "DZ",
  },
  {
    name: "Andorra",
    phone_extension: "+376",
    short_name: "AD",
  },
  {
    name: "Angola",
    phone_extension: "+244",
    short_name: "AO",
  },
  {
    name: "Argentina",
    phone_extension: "+54",
    short_name: "AR",
  },
  {
    name: "Armenia",
    phone_extension: "+374",
    short_name: "AM",
  },
  {
    name: "Australia",
    phone_extension: "+61",
    short_name: "AU",
  },
  {
    name: "Austria",
    phone_extension: "+43",
    short_name: "AT",
  },
  {
    name: "Azerbaijan",
    phone_extension: "+994",
    short_name: "AZ",
  },
  {
    name: "Bahamas",
    phone_extension: "+1-242",
    short_name: "BS",
  },
  {
    name: "Bahrain",
    phone_extension: "+973",
    short_name: "BH",
  },
  {
    name: "Bangladesh",
    phone_extension: "+880",
    short_name: "BD",
  },
  {
    name: "Barbados",
    phone_extension: "+1-246",
    short_name: "BB",
  },
  {
    name: "Belarus",
    phone_extension: "+375",
    short_name: "BY",
  },
  {
    name: "Belgium",
    phone_extension: "+32",
    short_name: "BE",
  },
  {
    name: "Belize",
    phone_extension: "+501",
    short_name: "BZ",
  },
  {
    name: "Benin",
    phone_extension: "+229",
    short_name: "BJ",
  },
  {
    name: "Bhutan",
    phone_extension: "+975",
    short_name: "BT",
  },
  {
    name: "Bolivia",
    phone_extension: "+591",
    short_name: "BO",
  },
  {
    name: "Bosnia and Herzegovina",
    phone_extension: "+387",
    short_name: "BA",
  },
  {
    name: "Botswana",
    phone_extension: "+267",
    short_name: "BW",
  },
  {
    name: "Brazil",
    phone_extension: "+55",
    short_name: "BR",
  },
  {
    name: "Brunei",
    phone_extension: "+673",
    short_name: "BN",
  },
  {
    name: "Bulgaria",
    phone_extension: "+359",
    short_name: "BG",
  },
  {
    name: "Burkina Faso",
    phone_extension: "+226",
    short_name: "BF",
  },
  {
    name: "Burundi",
    phone_extension: "+257",
    short_name: "BI",
  },
  {
    name: "Cabo Verde",
    phone_extension: "+238",
    short_name: "CV",
  },
  {
    name: "Cambodia",
    phone_extension: "+855",
    short_name: "KH",
  },
  {
    name: "Cameroon",
    phone_extension: "+237",
    short_name: "CM",
  },
  {
    name: "Canada",
    phone_extension: "+1",
    short_name: "CA",
  },
  {
    name: "Central African Republic",
    phone_extension: "+236",
    short_name: "CF",
  },
  {
    name: "Chad",
    phone_extension: "+235",
    short_name: "TD",
  },
  {
    name: "Chile",
    phone_extension: "+56",
    short_name: "CL",
  },
  {
    name: "China",
    phone_extension: "+86",
    short_name: "CN",
  },
  {
    name: "Colombia",
    phone_extension: "+57",
    short_name: "CO",
  },
  {
    name: "Comoros",
    phone_extension: "+269",
    short_name: "KM",
  },
  {
    name: "Congo",
    phone_extension: "+242",
    short_name: "CG",
  },
  {
    name: "Costa Rica",
    phone_extension: "+506",
    short_name: "CR",
  },
  {
    name: "Croatia",
    phone_extension: "+385",
    short_name: "HR",
  },
  {
    name: "Cuba",
    phone_extension: "+53",
    short_name: "CU",
  },
  {
    name: "Cyprus",
    phone_extension: "+357",
    short_name: "CY",
  },
  {
    name: "Czech Republic",
    phone_extension: "+420",
    short_name: "CZ",
  },
  {
    name: "Denmark",
    phone_extension: "+45",
    short_name: "DK",
  },
  {
    name: "Djibouti",
    phone_extension: "+253",
    short_name: "DJ",
  },
  {
    name: "Dominica",
    phone_extension: "+1-767",
    short_name: "DM",
  },
  {
    name: "Dominican Republic",
    phone_extension: "+1-809",
    short_name: "DO",
  },
  {
    name: "Ecuador",
    phone_extension: "+593",
    short_name: "EC",
  },
  {
    name: "Egypt",
    phone_extension: "+20",
    short_name: "EG",
  },
  {
    name: "El Salvador",
    phone_extension: "+503",
    short_name: "SV",
  },
  {
    name: "Equatorial Guinea",
    phone_extension: "+240",
    short_name: "GQ",
  },
  {
    name: "Eritrea",
    phone_extension: "+291",
    short_name: "ER",
  },
  {
    name: "Estonia",
    phone_extension: "+372",
    short_name: "EE",
  },
  {
    name: "Eswatini",
    phone_extension: "+268",
    short_name: "SZ",
  },
  {
    name: "Ethiopia",
    phone_extension: "+251",
    short_name: "ET",
  },
  {
    name: "Fiji",
    phone_extension: "+679",
    short_name: "FJ",
  },
  {
    name: "Finland",
    phone_extension: "+358",
    short_name: "FI",
  },
  {
    name: "France",
    phone_extension: "+33",
    short_name: "FR",
  },
  {
    name: "Gabon",
    phone_extension: "+241",
    short_name: "GA",
  },
  {
    name: "Gambia",
    phone_extension: "+220",
    short_name: "GM",
  },
  {
    name: "Georgia",
    phone_extension: "+995",
    short_name: "GE",
  },
  {
    name: "Germany",
    phone_extension: "+49",
    short_name: "DE",
  },
  {
    name: "Ghana",
    phone_extension: "+233",
    short_name: "GH",
  },
  {
    name: "Greece",
    phone_extension: "+30",
    short_name: "GR",
  },
  {
    name: "Grenada",
    phone_extension: "+1-473",
    short_name: "GD",
  },
  {
    name: "Guatemala",
    phone_extension: "+502",
    short_name: "GT",
  },
  {
    name: "Guinea",
    phone_extension: "+224",
    short_name: "GN",
  },
  {
    name: "Guinea-Bissau",
    phone_extension: "+245",
    short_name: "GW",
  },
  {
    name: "Guyana",
    phone_extension: "+592",
    short_name: "GY",
  },
  {
    name: "Haiti",
    phone_extension: "+509",
    short_name: "HT",
  },
  {
    name: "Honduras",
    phone_extension: "+504",
    short_name: "HN",
  },
  {
    name: "Hong Kong",
    phone_extension: "+852",
    short_name: "HK",
  },
  {
    name: "Hungary",
    phone_extension: "+36",
    short_name: "HU",
  },
  {
    name: "Iceland",
    phone_extension: "+354",
    short_name: "IS",
  },
  {
    name: "India",
    phone_extension: "+91",
    short_name: "IN",
  },
  {
    name: "Indonesia",
    phone_extension: "+62",
    short_name: "ID",
  },
  {
    name: "Iran",
    phone_extension: "+98",
    short_name: "IR",
  },
  {
    name: "Iraq",
    phone_extension: "+964",
    short_name: "IQ",
  },
  {
    name: "Ireland",
    phone_extension: "+353",
    short_name: "IE",
  },
  {
    name: "Israel",
    phone_extension: "+972",
    short_name: "IL",
  },
  {
    name: "Italy",
    phone_extension: "+39",
    short_name: "IT",
  },
  {
    name: "Jamaica",
    phone_extension: "+1-876",
    short_name: "JM",
  },
  {
    name: "Japan",
    phone_extension: "+81",
    short_name: "JP",
  },
  {
    name: "Jordan",
    phone_extension: "+962",
    short_name: "JO",
  },
  {
    name: "Kazakhstan",
    phone_extension: "+7",
    short_name: "KZ",
  },
  {
    name: "Kenya",
    phone_extension: "+254",
    short_name: "KE",
  },
  {
    name: "Kiribati",
    phone_extension: "+686",
    short_name: "KI",
  },
  {
    name: "Kuwait",
    phone_extension: "+965",
    short_name: "KW",
  },
  {
    name: "Kyrgyzstan",
    phone_extension: "+996",
    short_name: "KG",
  },
  {
    name: "Laos",
    phone_extension: "+856",
    short_name: "LA",
  },
  {
    name: "Latvia",
    phone_extension: "+371",
    short_name: "LV",
  },
  {
    name: "Lebanon",
    phone_extension: "+961",
    short_name: "LB",
  },
  {
    name: "Lesotho",
    phone_extension: "+266",
    short_name: "LS",
  },
  {
    name: "Liberia",
    phone_extension: "+231",
    short_name: "LR",
  },
  {
    name: "Libya",
    phone_extension: "+218",
    short_name: "LY",
  },
  {
    name: "Liechtenstein",
    phone_extension: "+423",
    short_name: "LI",
  },
  {
    name: "Lithuania",
    phone_extension: "+370",
    short_name: "LT",
  },
  {
    name: "Luxembourg",
    phone_extension: "+352",
    short_name: "LU",
  },
  {
    name: "Madagascar",
    phone_extension: "+261",
    short_name: "MG",
  },
  {
    name: "Malawi",
    phone_extension: "+265",
    short_name: "MW",
  },
  {
    name: "Malaysia",
    phone_extension: "+60",
    short_name: "MY",
  },
  {
    name: "Maldives",
    phone_extension: "+960",
    short_name: "MV",
  },
  {
    name: "Mali",
    phone_extension: "+223",
    short_name: "ML",
  },
  {
    name: "Malta",
    phone_extension: "+356",
    short_name: "MT",
  },
  {
    name: "Marshall Islands",
    phone_extension: "+692",
    short_name: "MH",
  },
  {
    name: "Mauritania",
    phone_extension: "+222",
    short_name: "MR",
  },
  {
    name: "Mauritius",
    phone_extension: "+230",
    short_name: "MU",
  },
  {
    name: "Mexico",
    phone_extension: "+52",
    short_name: "MX",
  },
  {
    name: "Micronesia",
    phone_extension: "+691",
    short_name: "FM",
  },
  {
    name: "Moldova",
    phone_extension: "+373",
    short_name: "MD",
  },
  {
    name: "Monaco",
    phone_extension: "+377",
    short_name: "MC",
  },
  {
    name: "Mongolia",
    phone_extension: "+976",
    short_name: "MN",
  },
  {
    name: "Montenegro",
    phone_extension: "+382",
    short_name: "ME",
  },
  {
    name: "Morocco",
    phone_extension: "+212",
    short_name: "MA",
  },
  {
    name: "Mozambique",
    phone_extension: "+258",
    short_name: "MZ",
  },
  {
    name: "Myanmar",
    phone_extension: "+95",
    short_name: "MM",
  },
  {
    name: "Namibia",
    phone_extension: "+264",
    short_name: "NA",
  },
  {
    name: "Nauru",
    phone_extension: "+674",
    short_name: "NR",
  },
  {
    name: "Nepal",
    phone_extension: "+977",
    short_name: "NP",
  },
  {
    name: "Netherlands",
    phone_extension: "+31",
    short_name: "NL",
  },
  {
    name: "New Zealand",
    phone_extension: "+64",
    short_name: "NZ",
  },
  {
    name: "Nicaragua",
    phone_extension: "+505",
    short_name: "NI",
  },
  {
    name: "Niger",
    phone_extension: "+227",
    short_name: "NE",
  },
  {
    name: "Nigeria",
    phone_extension: "+234",
    short_name: "NG",
  },
  {
    name: "North Korea",
    phone_extension: "+850",
    short_name: "KP",
  },
  {
    name: "North Macedonia",
    phone_extension: "+389",
    short_name: "MK",
  },
  {
    name: "Norway",
    phone_extension: "+47",
    short_name: "NO",
  },
  {
    name: "Oman",
    phone_extension: "+968",
    short_name: "OM",
  },
  {
    name: "Pakistan",
    phone_extension: "+92",
    short_name: "PK",
  },
  {
    name: "Palau",
    phone_extension: "+680",
    short_name: "PW",
  },
  {
    name: "Palestine",
    phone_extension: "+970",
    short_name: "PS",
  },
  {
    name: "Panama",
    phone_extension: "+507",
    short_name: "PA",
  },
  {
    name: "Papua New Guinea",
    phone_extension: "+675",
    short_name: "PG",
  },
  {
    name: "Paraguay",
    phone_extension: "+595",
    short_name: "PY",
  },
  {
    name: "Peru",
    phone_extension: "+51",
    short_name: "PE",
  },
  {
    name: "Philippines",
    phone_extension: "+63",
    short_name: "PH",
  },
  {
    name: "Poland",
    phone_extension: "+48",
    short_name: "PL",
  },
  {
    name: "Portugal",
    phone_extension: "+351",
    short_name: "PT",
  },
  {
    name: "Qatar",
    phone_extension: "+974",
    short_name: "QA",
  },
  {
    name: "Romania",
    phone_extension: "+40",
    short_name: "RO",
  },
  {
    name: "Russia",
    phone_extension: "+7",
    short_name: "RU",
  },
  {
    name: "Rwanda",
    phone_extension: "+250",
    short_name: "RW",
  },
  {
    name: "Saint Kitts and Nevis",
    phone_extension: "+1-869",
    short_name: "KN",
  },
  {
    name: "Saint Lucia",
    phone_extension: "+1-758",
    short_name: "LC",
  },
  {
    name: "Saint Vincent and the Grenadines",
    phone_extension: "+1-784",
    short_name: "VC",
  },
  {
    name: "Samoa",
    phone_extension: "+685",
    short_name: "WS",
  },
  {
    name: "San Marino",
    phone_extension: "+378",
    short_name: "SM",
  },
  {
    name: "Sao Tome and Principe",
    phone_extension: "+239",
    short_name: "ST",
  },
  {
    name: "Saudi Arabia",
    phone_extension: "+966",
    short_name: "SA",
  },
  {
    name: "Senegal",
    phone_extension: "+221",
    short_name: "SN",
  },
  {
    name: "Serbia",
    phone_extension: "+381",
    short_name: "RS",
  },
  {
    name: "Seychelles",
    phone_extension: "+248",
    short_name: "SC",
  },
  {
    name: "Sierra Leone",
    phone_extension: "+232",
    short_name: "SL",
  },
  {
    name: "Singapore",
    phone_extension: "+65",
    short_name: "SG",
  },
  {
    name: "Slovakia",
    phone_extension: "+421",
    short_name: "SK",
  },
  {
    name: "Slovenia",
    phone_extension: "+386",
    short_name: "SI",
  },
  {
    name: "Solomon Islands",
    phone_extension: "+677",
    short_name: "SB",
  },
  {
    name: "Somalia",
    phone_extension: "+252",
    short_name: "SO",
  },
  {
    name: "South Africa",
    phone_extension: "+27",
    short_name: "ZA",
  },
  {
    name: "South Korea",
    phone_extension: "+82",
    short_name: "KR",
  },
  {
    name: "South Sudan",
    phone_extension: "+211",
    short_name: "SS",
  },
  {
    name: "Spain",
    phone_extension: "+34",
    short_name: "ES",
  },
  {
    name: "Sri Lanka",
    phone_extension: "+94",
    short_name: "LK",
  },
  {
    name: "Sudan",
    phone_extension: "+249",
    short_name: "SD",
  },
  {
    name: "Suriname",
    phone_extension: "+597",
    short_name: "SR",
  },
  {
    name: "Sweden",
    phone_extension: "+46",
    short_name: "SE",
  },
  {
    name: "Switzerland",
    phone_extension: "+41",
    short_name: "CH",
  },
  {
    name: "Syria",
    phone_extension: "+963",
    short_name: "SY",
  },
  {
    name: "Taiwan",
    phone_extension: "+886",
    short_name: "TW",
  },
  {
    name: "Tajikistan",
    phone_extension: "+992",
    short_name: "TJ",
  },
  {
    name: "Tanzania",
    phone_extension: "+255",
    short_name: "TZ",
  },
  {
    name: "Thailand",
    phone_extension: "+66",
    short_name: "TH",
  },
  {
    name: "Timor-Leste",
    phone_extension: "+670",
    short_name: "TL",
  },
  {
    name: "Togo",
    phone_extension: "+228",
    short_name: "TG",
  },
  {
    name: "Tonga",
    phone_extension: "+676",
    short_name: "TO",
  },
  {
    name: "Trinidad and Tobago",
    phone_extension: "+1-868",
    short_name: "TT",
  },
  {
    name: "Tunisia",
    phone_extension: "+216",
    short_name: "TN",
  },
  {
    name: "Turkey",
    phone_extension: "+90",
    short_name: "TR",
  },
  {
    name: "Turkmenistan",
    phone_extension: "+993",
    short_name: "TM",
  },
  {
    name: "Tuvalu",
    phone_extension: "+688",
    short_name: "TV",
  },
  {
    name: "Uganda",
    phone_extension: "+256",
    short_name: "UG",
  },
  {
    name: "Ukraine",
    phone_extension: "+380",
    short_name: "UA",
  },
  {
    name: "United Arab Emirates",
    phone_extension: "+971",
    short_name: "AE",
  },
  {
    name: "United Kingdom",
    phone_extension: "+44",
    short_name: "GB",
  },
  {
    name: "United States",
    phone_extension: "+1",
    short_name: "US",
  },
  {
    name: "Uruguay",
    phone_extension: "+598",
    short_name: "UY",
  },
  {
    name: "Uzbekistan",
    phone_extension: "+998",
    short_name: "UZ",
  },
  {
    name: "Vanuatu",
    phone_extension: "+678",
    short_name: "VU",
  },
  {
    name: "Vatican City",
    phone_extension: "+379",
    short_name: "VA",
  },
  {
    name: "Venezuela",
    phone_extension: "+58",
    short_name: "VE",
  },
  {
    name: "Vietnam",
    phone_extension: "+84",
    short_name: "VN",
  },
  {
    name: "Yemen",
    phone_extension: "+967",
    short_name: "YE",
  },
  {
    name: "Zambia",
    phone_extension: "+260",
    short_name: "ZM",
  },
  {
    name: "Zimbabwe",
    phone_extension: "+263",
    short_name: "ZW",
  },
];
