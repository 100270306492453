import axios from "axios";
import { baseURL } from "./utils/constants";

const authAxios = axios.create({
  baseURL,
});

authAxios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("JWT_TOKEN");
    const userId = localStorage.getItem("USER_ID");

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    if (userId && !config.url.includes("user_id")) {
      const separator = config.url.includes("?") ? "&" : "?";
      config.url += `${separator}user_id=${userId}`;
    }

    if (process.env.NODE_ENV === "development")
      config.headers.set("ngrok-skip-browser-warning", 1);

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

authAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem("JWT_TOKEN");
      localStorage.removeItem("USER_ID");
      const originalPath = window.location.pathname + window.location.search;
      if (!originalPath.includes("reroute")) {
        window.location = `/login?reroute=${originalPath}`;
      }
    }
    return Promise.reject(error);
  }
);

export const authAxiosFetcher = (url) =>
  authAxios.get(url).then((res) => res.data);

export default authAxios;
