import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { isMobile } from "react-device-detect";
import Slide from "@mui/material/Slide";
import Dialog from "@mui/material/Dialog";
import { useSwipeable } from "react-swipeable";
import CloseIcon from "@mui/icons-material/Close";
import { Stack } from "@mui/system";

import "./Component.scss";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: 650,
  width: 780,
  bgcolor: "background.paper",
  borderRadius: 5,
  p: 4,
  overflowY: "scroll",
};

export const DownloadAppModal = ({ open, handleClose }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div
          style={{
            display: "flex",
            position: "fixed",
            top: 0,
            width: "90%",
          }}
        >
          <button style={{ height: "4rem" }} onClick={handleClose}>
            <CloseIcon
              style={{
                fontSize: "20px",
                marginLeft: "-3px",
              }}
            />
          </button>
        </div>
        <div className="DownloadAppModalWrapper">
          <span className="DownloadAppModalHeader">
            Only available on our app
          </span>
          <div className="DownloadAppModalmageWrapper">
            Download our app to get access to free reservations and exclusive
            welcome deals
          </div>

          <img
            src="images/qr-to-app-explore-page.png"
            style={{ height: "15rem", marginTop: "1rem" }}
          />

          <Stack
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            style={{ marginTop: "3rem" }}
          >
            <button
              onClick={() =>
                window.open(
                  "https://apps.apple.com/sg/app/sotravel/id6447177449",
                  "_blank"
                )
              }
            >
              <img
                src="/images/AppleDownloadIcon.png"
                style={{ width: "10.1rem" }}
              />
            </button>
            <button
              onClick={() =>
                window.open(
                  "https://play.google.com/store/apps/details?id=com.sotravel.sotravel",
                  "_blank"
                )
              }
            >
              <img
                src="/images/GooglePlayDownloadIcon.png"
                style={{ width: "10rem" }}
              />
            </button>
          </Stack>

          <div style={{ padding: "2rem" }}></div>
        </div>
      </Box>
    </Modal>
  );
};
